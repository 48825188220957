


@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

html {
    font-family: var(--primary-font);
    color: var(--dark-color);
    scroll-behavior: smooth;
    overflow-x: hidden;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
    color: var(--primary-color)
}

body {
    overflow-x: hidden;
    color:white;
    background-image: url("../../../public/assets/images/background.png");
    background-repeat: no-repeat, repeat;
    background-color: #000652;
}

:root {
    --primary-font: 'Plus Jakarta Sans', sans-serif;
    --secondary-font: 'Open Sans', sans-serif;
    --dark-color: #000000;
    --primary-color: #791DB7;
    --info-color: #23907d;
    --success-color: #189d0e;
    --warning-color: #123123;
    --danger-color: #e20000;
    --secondary-color: #707070;
    --light-color: #bbb;
    --white-color: #fff;
    --general-transition: all .4s ease;
}

.app-container {
    display: flex;
}

.react-player {
    border-radius: 25px;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.player-wrapper{
    position: relative;
    height: 100%;
}

.news-widget{
    background-color: white;
    height: 100%;
    border-radius: 25px;
}
.news-detail{
    display: block;
    position: relative;
    height: 92vh;
}

.news-image{
    width: 100%;
    height: 30vh;
    color: #ffffff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right center;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}
.news-title{
    font-family: var(--primary-font) !important;
    z-index: 10;
    min-height: 100px;
    color:#01247D;
    font-weight: 800;
    font-size: 38px;
    margin:15px;
    text-transform: uppercase;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Üç satırda kısalt */
    -webkit-box-orient: vertical;
    overflow: hidden;
}


.news-desc{
    color:#01247D;
    font-size: 29px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 12; /* Üç satırda kısalt */
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-right: 10px;
    margin-left: 10px;

}

.news-desc p{
    max-width: 100%;
}

.news-share{
    background-color: #000652;
    position: absolute;
    display: block;
    padding:10px;
    margin:10px;
    border-radius: 10px;
    text-align: center;
    justify-content: center;
    bottom:0;
    left: 0;
    right: 0;
    width: 95%;
}

.weather-widget{
    display: flex;
    background-color: #EFF7FF;
    height: 100%;
    min-height: 225px;
    border-radius: 25px;
    color: #000652;
}

.weather-district{
    display:flex;
    height: 100%;
    align-items: center;
    color: #00419E;
    padding-bottom: 10px;
}

.district-temp{
    font-size: 8rem;
    font-weight: bold;
    margin-left: 20px;
    color: #00419E;
}

.district-name{
    margin-left: 10px;
    align-items: center;
}

.district-name-title{
    font-size: 20px;
    font-weight: 500;
}

.district-name-desc{
    font-size: 25px;
    font-weight: 500;
}

.district-icon img{
    height: 150px;
    min-width: 150px;
    width: 100%;
    padding:20px;
}

.weather-city{
    display: flex;
    position: relative;
    width: 100%;
    border-bottom-right-radius: 23px;
    border-top-right-radius: 23px;
    padding: 10px;
    background: rgb(0,65,158);
    background: linear-gradient(180deg, rgba(0,65,158,1) 0%, rgba(0,25,105,1) 100%);
}

.city-item{
    display: block;
    position: relative;
    background-color: #0049B2;
    width: 150px;
    height: 100%;
    border-radius: 10px;
    padding: 10px;
    color: white;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.city-name{
    text-align: center;
    font-size: 20px;
    margin-top:10px;
}

.city-temp-icon img{
    width: 75px;
    height: 75px;
    margin: 5px;
}

.city-temp{
    font-size: 30px;
    font-weight: 500;
}